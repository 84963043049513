import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Nav, Navbar } from 'react-bootstrap';
import axios from 'axios';
import DOMPurify from 'dompurify';
import Masonry from 'react-masonry-css';
import Carousel from 'nuka-carousel';

import { ElementArticlePreview, ElementArticlePreview2, ElementArticleQuotePreview, ElementVacancyPreview, ElementFooterLink, ElementTitleH1, ElementTitleH2, ElementTitleH2Small } from './Element';

import { UtilContent, UtilTranslate, UtilRoute } from './Util';

// Props: debug, ip_address, translation_data, translation_language
function SectionAbout(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'aboutsarajevo',
                            'aboutthomas',
                            'aboutrusmira'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text

                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    // Photos
    const [photo_about_data, photo_about_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address+'/backend/financeteams/api/photo_about');

                photo_about_set_data(response.data);
                // <str> photo

                if (debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [debug, ip_address]);

    let photos = [];
    if (photo_about_data !== null)
    {
        for (let i = 0; i < parseInt(photo_about_data.length); i++)
        {
            photos.push(
                <div key={i} className="content-about-photo-container">
                    <img className="content-about-photo" alt="Office" src={props.upload_images+'/'+photo_about_data[i][0]} />
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            <div className="content-about-sarajevo">
                <div className="content-about-sarajevo-container">
                    <div className="container">
                        <div className="content-about-sarajevo-title-container">
                            <div className="content-about-sarajevo-icon"></div>
                            <div className="content-about-sarajevo-title">{UtilTranslate(props.translation_data, 'content_about_sarajevo', props.debug)}</div>
                        </div>
                    </div>
                </div>
                <div className="content-about-sarajevo-outro"></div>
            </div>
            <section className="content-section-about">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'content_about_title', props.debug)} />

                            { content_data !== null &&
                                <div className="text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'aboutsarajevo', props.debug)}}></div>
                            }

                            <div className="content-about-photo-carousel">
                                { photos.length > 0 &&
                                    <React.Fragment>
                                        <div className="d-block d-sm-none">
                                            <Carousel
                                                slidesToShow={1}
                                                cellSpacing="30"
                                                renderCenterLeftControls={({ previousSlide, previousDisabled }) => previousDisabled ? null : (
                                                    <button className="content-about-carousel-button-left" onClick={previousSlide}>
                                                        <div className="content-about-carousel-chevron-left"></div>
                                                    </button>
                                                )}
                                                renderCenterRightControls={({ nextSlide, nextDisabled}) => nextDisabled ? null : (
                                                    <button className="content-about-carousel-button-right" onClick={nextSlide}>
                                                        <div className="content-about-carousel-chevron-right"></div>
                                                    </button>
                                                )} >
                                                
                                                {photos}
                                            </Carousel>
                                        </div>
                                        <div className="d-none d-sm-block d-lg-none">
                                            <Carousel
                                                slidesToShow={2}
                                                cellSpacing="30"
                                                renderCenterLeftControls={({ previousSlide, previousDisabled }) => previousDisabled ? null : (
                                                    <button className="content-about-carousel-button-left" onClick={previousSlide}>
                                                        <div className="content-about-carousel-chevron-left"></div>
                                                    </button>
                                                )}
                                                renderCenterRightControls={({ nextSlide, nextDisabled}) => nextDisabled ? null : (
                                                    <button className="content-about-carousel-button-right" onClick={nextSlide}>
                                                        <div className="content-about-carousel-chevron-right"></div>
                                                    </button>
                                                )} >
                                                
                                                {photos}
                                            </Carousel>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <Carousel
                                                slidesToShow={3}
                                                cellSpacing="30"
                                                renderCenterLeftControls={({ previousSlide, previousDisabled }) => previousDisabled ? null : (
                                                    <button className="content-about-carousel-button-left" onClick={previousSlide}>
                                                        <div className="content-about-carousel-chevron-left"></div>
                                                    </button>
                                                )}
                                                renderCenterRightControls={({ nextSlide, nextDisabled}) => nextDisabled ? null : (
                                                    <button className="content-about-carousel-button-right" onClick={nextSlide}>
                                                        <div className="content-about-carousel-chevron-right"></div>
                                                    </button>
                                                )} >
                                                
                                                {photos}
                                            </Carousel>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-about-profile-intro"></div>
            <section className="content-section-about-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="content-about-profile-photo-thomas"></div>
                            <div className="content-about-profile-name">Thomas van Wersch</div>
                            <div className="content-about-profile-title">{UtilTranslate(props.translation_data, 'content_about_titlethomas', props.debug)}</div>

                            <div className="content-about-profile-quote-container">
                                <div className="content-about-profile-quote-icon"></div>
                                { content_data !== null &&
                                    <div className="content-about-profile-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[1]), 'aboutthomas', props.debug)}}></div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="content-about-profile-photo-rusmira"></div>
                            <div className="content-about-profile-name">Rusmira Tinjak</div>
                            <div className="content-about-profile-title">{UtilTranslate(props.translation_data, 'content_about_titlerusmira', props.debug)}</div>

                            <div className="content-about-profile-quote-container">
                                <div className="content-about-profile-quote-icon"></div>
                                { content_data !== null &&
                                    <div className="content-about-profile-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[2]), 'aboutrusmira', props.debug)}}></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-about-profile-outro"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data, translation_language, upload_images
function SectionArticle(props)
{
    const {article_slug} = useParams();
    const [article_data, article_set_data] = useState(null);
    
    const navigate = useNavigate();

    // Article
    let debug = props.debug;
    let ip_address = props.ip_address;
    let routes_urls = props.routes_urls;
    let translation_language = props.translation_language;
    let upload_images = props.upload_images;
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        slug: article_slug,
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/article', { params });

                    let data = response.data;
                    data[14] = data[14].replace('<p>$text_quote1</p>', '<div class="content-article-quote-container"><div class="content-article-quote-icon"></div><div class="content-article-quote">'+data[9]+'</div></div>');
                    data[14] = data[14].replace('<p>$text_quote2</p>', '<div class="content-article-quote-container"><div class="content-article-quote-icon"></div><div class="content-article-quote">'+data[10]+'</div></div>');
                    data[14] = data[14].replace('<p>$text_quote3</p>', '<div class="content-article-quote-container"><div class="content-article-quote-icon"></div><div class="content-article-quote">'+data[11]+'</div></div>');
                    data[14] = data[14].replace('<p>$text_quote4</p>', '<div class="content-article-quote-container"><div class="content-article-quote-icon"></div><div class="content-article-quote">'+data[12]+'</div></div>');
                    data[14] = data[14].replace('<p>$text_quote5</p>', '<div class="content-article-quote-container"><div class="content-article-quote-icon"></div><div class="content-article-quote">'+data[13]+'</div></div>');

                    data[14] = data[14].replace('<p>$text_photo1</p>', '<img src="'+upload_images+'/'+data[4]+'" alt="'+data[1]+'" class="content-article-photo-text" width="100%" />');
                    data[14] = data[14].replace('<p>$text_photo2</p>', '<img src="'+upload_images+'/'+data[5]+'" alt="'+data[1]+'" class="content-article-photo-text" width="100%" />');
                    data[14] = data[14].replace('<p>$text_photo3</p>', '<img src="'+upload_images+'/'+data[6]+'" alt="'+data[1]+'" class="content-article-photo-text" width="100%" />');
                    data[14] = data[14].replace('<p>$text_photo4</p>', '<img src="'+upload_images+'/'+data[7]+'" alt="'+data[1]+'" class="content-article-photo-text" width="100%" />');
                    data[14] = data[14].replace('<p>$text_photo5</p>', '<img src="'+upload_images+'/'+data[8]+'" alt="'+data[1]+'" class="content-article-photo-text" width="100%" />');

                    article_set_data(data);
                    // <str> articletype (standard, client, profile)
                    // <str> title
                    // <str> date
                    // <str> photo
                    // <str> text_photo1
                    // <str> text_photo2
                    // <str> text_photo3
                    // <str> text_photo4
                    // <str> text_photo5
                    // <str> text_quote1
                    // <str> text_quote2
                    // <str> text_quote3
                    // <str> text_quote4
                    // <str> text_quote5
                    // <str> text
                    // <str> intro

                    if (debug === true)
                        console.log(data);
                }

                catch (error)
                {
                    navigate(UtilRoute(translation_language, routes_urls, 'article_overview', null));
                }
            }

            get();
        }
    }, [debug, ip_address, article_slug, routes_urls, translation_language, upload_images, navigate]);

    return (
        <React.Fragment>
            { article_data !== null &&
                <React.Fragment>
                    <section className="content-section-article-banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    { props.translation_language === 'bs-BA' &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="content-vacancy-language">
                                                    <div className="content-vacancy-language-flag-en"></div>{UtilTranslate(props.translation_data, 'content_info_englishcontent', props.debug)}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="col-12 d-block d-md-none">
                                        <h1 className="content-article-title-h1-mobile">{article_data[1]}</h1>
                                        <div className="content-article-subtitle-mobile">{article_data[2]}</div>

                                        <img src={props.upload_images+'/'+article_data[3]} alt={article_data[3]} width="100%" className="content-article-photo" />
                                    </div>
                                    <div className="col-12 d-none d-md-block">
                                        <div className="content-article-container">
                                            <div className="content-article-title-container">
                                                <div className="d-block d-lg-none">
                                                    <h1 className="content-article-title-h1-tablet">{article_data[1]}</h1>
                                                    <div className="content-article-subtitle-tablet">{article_data[2]}</div>
                                                </div>
                                                <div className="d-none d-lg-block">
                                                    <h1 className="content-article-title-h1-desktop">{article_data[1]}</h1>
                                                    <div className="content-article-subtitle-desktop">{article_data[2]}</div>
                                                </div>
                                            </div>

                                            <img src={props.upload_images+'/'+article_data[3]} alt={article_data[3]} width="50%" className="content-article-photo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="content-article-intro"></div>
                    <section className="content-section-article-intro">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="content-article-intro-text-container">
                                        <div className="content-article-intro-text-icon"></div>
                                        <div className="d-block d-md-none">
                                            <div className="content-article-intro-text-mobile">{article_data[15]}</div>
                                        </div>
                                        <div className="d-none d-md-block">
                                            <div className="content-article-intro-text-desktop">{article_data[15]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="content-article-outro"></div>
                    <section className="content-section-standard">
                        <div className="container">
                            <div className="row">
                                { article_data !== null &&
                                    <React.Fragment>
                                        <div className="col-2 d-none d-lg-block"></div>
                                        <div className="col-12 col-lg-8">
                                            <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article_data[14])}}></div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language, upload_images
function SectionArticleOverview(props)
{
    // Articles
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [article_data, article_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/article_overview', { params });

                    article_set_data(response.data)
                    // <array> [<str> title, <str> date, <str> slug, <str> photo]

                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    let articles = [];
    if (article_data !== null)
    {
        for (let i = 0; i < parseInt(article_data.length); i++)
        {
            articles.push(
                <React.Fragment key={i}>
                    <ElementArticlePreview
                        button={UtilTranslate(props.translation_data, 'content_articles_button', props.debug)}
                        date={article_data[i][1]}
                        image={props.upload_images+'/'+article_data[i][3]}
                        title={article_data[i][0]}
                        url={UtilRoute(props.translation_language, props.routes_urls, 'article', article_data[i][2])} />
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <section className="content-section-articleoverview-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-block d-md-none">
                            <h1 className="content-articleoverview-title-mobile">{UtilTranslate(props.translation_data, 'content_articles_title', props.debug)}</h1>
                            <div className="content-articleoverview-subtitle-mobile">{UtilTranslate(props.translation_data, 'content_articles_subtitle', props.debug)}</div>
                            
                            <img src="/image/photo_articles.jpg" className="content-articleoverview-photo" alt="Articles" width="100%" />
                        </div>
                        <div className="col-12 d-none d-md-block">
                            <div className="content-articleoverview-container">
                                <div className="content-articleoverview-title-container">
                                    <div className="d-block d-lg-none">
                                        <h1 className="content-articleoverview-title-tablet">{UtilTranslate(props.translation_data, 'content_articles_title', props.debug)}</h1>
                                        <div className="content-articleoverview-subtitle-tablet">{UtilTranslate(props.translation_data, 'content_articles_subtitle', props.debug)}</div>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <h1 className="content-articleoverview-title-desktop">{UtilTranslate(props.translation_data, 'content_articles_title', props.debug)}</h1>
                                        <div className="content-articleoverview-subtitle-desktop">{UtilTranslate(props.translation_data, 'content_articles_subtitle', props.debug)}</div>
                                    </div>
                                </div>

                                <img src="/image/photo_articles.jpg" className="content-articleoverview-photo" alt="Articles" width="35%" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-articleoverview-intro"></div>
            <section className="content-section-articleoverview">
                <div className="container">
                    <div className="row">
                        { article_data === null &&
                            <div className="col-12">
                                <div className="loading"></div>
                            </div>
                        }

                        <div className="col-12">
                            <Masonry
                                breakpointCols={{default: 3, 991: 2, 560: 1}}
                                className="masonry"
                                columnClassName="masonry-column">
                                
                                {articles}
                            </Masonry>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-articleoverview-outro"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language, upload_images
function SectionArticleProfiles(props)
{
    // Article quotes
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [article_profiles_data, article_profiles_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {  
                try
                {
                    let params = {
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/article_profiles', { params });

                    article_profiles_set_data(response.data);
                    // <array> [<str> title, <str> date, <str> slug, <str> photo]
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    let article_profiles = [];
    if (article_profiles_data !== null)
    {
        for (let i = 0; i < parseInt(article_profiles_data.length); i++)
        {
            article_profiles.push(
                <React.Fragment key={i}>
                    <ElementArticlePreview2
                        button={UtilTranslate(props.translation_data, 'content_articles_button', props.debug)}
                        date={article_profiles_data[i][1]}
                        image={props.upload_images+'/'+article_profiles_data[i][3]}
                        title={article_profiles_data[i][0]}
                        url={UtilRoute(props.translation_language, props.routes_urls, 'article', article_profiles_data[i][2])} />
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            { article_profiles.length > 0 &&
                <section className="content-section-profiles">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <ElementTitleH2
                                    text={UtilTranslate(props.translation_data, 'content_profiles_title', props.debug)} />
                            </div>

                            { article_profiles_data === null &&
                                <div className="col-12">
                                    <div className="loading"></div>
                                </div>
                            }

                            <div className="col-12">
                                <Masonry
                                    breakpointCols={{default: 3, 991: 2, 560: 1}}
                                    className="masonry"
                                    columnClassName="masonry-column">
                                    
                                    {article_profiles}
                                </Masonry>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </React.Fragment>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language, upload_images
function SectionArticleQuotes(props)
{
    // Article quotes
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [article_quotes_data, article_quotes_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {    
                try
                {
                    let params = {
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/article_quotes', { params });

                    article_quotes_set_data(response.data);
                    // <array> [<str> photo, <str> name, <str> quote, <str> slug]
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    let article_quotes = [];
    if (article_quotes_data !== null)
    {
        for (let i = 0; i < parseInt(article_quotes_data.length); i++)
        {
            article_quotes.push(
                <React.Fragment key={i}>
                    <ElementArticleQuotePreview
                        button={UtilTranslate(props.translation_data, 'content_quotes_button', props.debug)}
                        image={props.upload_images+'/'+article_quotes_data[i][0]}
                        name={article_quotes_data[i][1]}
                        url={UtilRoute(props.translation_language, props.routes_urls, 'article', article_quotes_data[i][3])}
                        quote={article_quotes_data[i][2]} />
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <div className="content-articlequote-intro"></div>
            <section className="content-section-articlequotes">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-block d-lg-none">
                                <h2 className="content-articlequote-title-mobile">{UtilTranslate(props.translation_data, 'content_quotes_title', props.debug)}</h2>
                            </div>
                            <div className="d-none d-lg-block">
                                <h2 className="content-articlequote-title-desktop">{UtilTranslate(props.translation_data, 'content_quotes_title', props.debug)}</h2>
                            </div>
                        </div>

                        { article_quotes_data === null &&
                            <div className="col-12">
                                <div className="loading"></div>
                            </div>
                        }
                        
                        <div className="col-12">
                            <Masonry
                                breakpointCols={{default: 3, 991: 2, 560: 1}}
                                className="masonry"
                                columnClassName="masonry-column">
                                
                                {article_quotes}
                            </Masonry>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-articlequote-outro"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language, upload_images
function SectionArticleRelated(props)
{
    const {article_slug} = useParams();
    const navigate = useNavigate();

    // Article related
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [article_related_data, article_related_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        slug: article_slug,
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/article_related', { params });
                    
                    article_related_set_data(response.data);
                    // <array> [<str> title, <str> date, <str> slug]
                    
                    if (debug === true)
                        console.log(response.data);   
                }

                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language, article_slug, navigate]);

    let related_articles = [];
    if (article_related_data !== null)
    {
        for (let i = 0; i < parseInt(article_related_data.length); i++)
        {
            related_articles.push(
                <React.Fragment key={i}>
                    <ElementArticlePreview2
                        button={UtilTranslate(props.translation_data, 'content_articles_button', props.debug)}
                        date={article_related_data[i][1]}
                        image={props.upload_images+'/'+article_related_data[i][3]}
                        title={article_related_data[i][0]}
                        url={UtilRoute(props.translation_language, props.routes_urls, 'article', article_related_data[i][2])} />
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            { related_articles.length > 0 &&
                <section className="content-section-standard">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <ElementTitleH2
                                    text={UtilTranslate(props.translation_data, 'content_relatedarticles_title', props.debug)} />
                            </div>

                            { article_related_data === null &&
                                <div className="col-12">
                                    <div className="loading"></div>
                                </div>
                            }

                            <div className="col-12">
                                <Masonry
                                    breakpointCols={{default: 3, 991: 2, 560: 1}}
                                    className="masonry"
                                    columnClassName="masonry-column">
                                    
                                    {related_articles}
                                </Masonry>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </React.Fragment>
    );
}

// Props: debug, routes_urls, translation_data, translation_language
function SectionBanner(props)
{
    return (
        <section className="banner-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-block d-lg-none">
                        <h1 className="banner-title-mobile">{UtilTranslate(props.translation_data, 'banner_title', props.debug)}</h1>
                        <div className="banner-subtitle-mobile">{UtilTranslate(props.translation_data,'banner_text', props.debug)}</div>
                        <img src="/image/photo_banner.jpg" alt="FinanceTeams" width="100%" />
                    </div>
                    <div className="col-5 d-none d-lg-block">
                        <h1 className="banner-title-desktop">{UtilTranslate(props.translation_data, 'banner_title', props.debug)}</h1>
                        <div className="banner-subtitle-desktop">{UtilTranslate(props.translation_data,'banner_text', props.debug)}</div>
                    </div>
                    <div className="col-7 d-none d-lg-block">
                        <img src="/image/photo_banner.jpg" alt="FinanceTeams" width="100%" />
                    </div>
                </div>
                <div className="row banner-subsection">
                    <div className="col-12">
                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'howwework', null)}>
                            <div className="banner-button">
                                {UtilTranslate(props.translation_data, 'banner_button', props.debug)}
                            </div>
                        </Link>
                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'onboarding', null)}>
                            <div className="banner-button-alt">
                                {UtilTranslate(props.translation_data, 'navigation_onboarding', props.debug)}<div className="banner-button-alt-chevron"></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionDisclaimer(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'disclaimer'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="content-section-standard">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'navigation_disclaimer', props.debug)} />
        
                            { content_data !== null &&
                                <div className="text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, routes_urls, translation_data, translation_language
function SectionFooter(props)
{
    return (
        <section className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2">
                        <div className="footer-container-logo">
                            <div className="footer-logo"></div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="footer-container-paragraph">
                            <div className="footer-text">
                                <div className="footer-title">{UtilTranslate(props.translation_data, 'footer_office', props.debug)} Heerlen</div>
                                Lebe WTC Heerlen<br/>
                                Vogt 21<br/>
                                6422 RK Heerlen<br/>
                                {UtilTranslate(props.translation_data, 'footer_netherlands', props.debug)}
                            </div>
                        </div>
                        <div className="footer-container-paragraph">
                            <div className="footer-text">
                                <div className="footer-title">{UtilTranslate(props.translation_data, 'footer_office', props.debug)} Sarajevo</div>
                                Sarajevo City Centre<br/>
                                Vrbanja 1, Sarajevo 71000<br/>
                                {UtilTranslate(props.translation_data, 'footer_bosniaandherzegovina', props.debug)}
                            </div>
                        </div>
                        <div className="footer-container-paragraph">
                            <div className="footer-text">
                                <div className="footer-title">{UtilTranslate(props.translation_data, 'footer_office', props.debug)} Tirana</div>
                                Rruga Ismail Qemali<br/>
                                Green Park Complex,<br/>
                                Building 1, 5th floor, Ap.19<br/>
                                {UtilTranslate(props.translation_data, 'footer_albania', props.debug)}
                            </div>
                        </div>

                        <div className="footer-container-paragraph">
                            <div className="footer-text">
                                <div className="footer-title">{UtilTranslate(props.translation_data, 'footer_updates', props.debug)}</div>
                                <a href="https://www.linkedin.com/company/financeteams" target="_blank" rel="noreferrer" className="footer-button-linkedin">{UtilTranslate(props.translation_data, 'footer_linkedin_button', props.debug)}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-container-links">
                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'home', null)}
                                text={UtilTranslate(props.translation_data, 'navigation_home', props.debug)} />

                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'howwework', null)}
                                text={UtilTranslate(props.translation_data, 'navigation_howwework', props.debug)} />

                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'onboarding', null)}
                                text={UtilTranslate(props.translation_data, 'navigation_onboarding', props.debug)} />

                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'article_overview', null)}
                                text={UtilTranslate(props.translation_data,'navigation_articles', props.debug)} />

                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'about', null)}
                                text={UtilTranslate(props.translation_data,'navigation_about', props.debug)} />

                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'vacancy_overview', null)}
                                text={UtilTranslate(props.translation_data, 'navigation_vacancies', props.debug)} />

                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'disclaimer', null)}
                                text={UtilTranslate(props.translation_data, 'navigation_disclaimer', props.debug)} />

                            <ElementFooterLink
                                to={UtilRoute(props.translation_language, props.routes_urls, 'privacy', null)}
                                text={UtilTranslate(props.translation_data, 'navigation_privacy', props.debug)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr className="footer-line" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-text">
                            {UtilTranslate(props.translation_data, 'footer_content', props.debug)} ©{new Date().getFullYear()} FinanceTeams BV. KVK 83653171. {UtilTranslate(props.translation_data, 'footer_platform', props.debug)} ©{new Date().getFullYear()} <a className="footer-link-alt" href="https://thissen.ai/" target="_blank" rel="noreferrer">Thissen AI®</a>. {UtilTranslate(props.translation_data, 'footer_legal', props.debug)}
                        </div>
                    </div>

                        { /*}

                        <hr className="footer-line" />

                        <div className="footer-spacing">
                            <span className="footer-text">
                                {UtilTranslate(props.translation_data, 'footer_content', props.debug)} ©{new Date().getFullYear()} FinanceTeams BV. KVK 83653171. {UtilTranslate(props.translation_data, 'footer_platform', props.debug)} ©{new Date().getFullYear()} <a className="footer-link-alt" href="https://thissen.ai/" target="_blank" rel="noreferrer">Thissen AI®</a>. {UtilTranslate(props.translation_data, 'footer_legal', props.debug)}
                            </span>
                        </div>
                    </div>
                    */ }

                    
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language
function SectionFormContact(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'formcontact'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text

                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    // Form
    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);
    
    const handleSubmit = (event) => {
        form_set_loading(true);
        
        const post = async () => {
            try
            {
                let form_description_corrected = form_description;
                if (form_description_corrected === '')
                    form_description_corrected = '-';

                const data = {
                    name: form_name,
                    phone: form_phone,
                    email: form_email,
                    description: form_description_corrected,
                    terms: form_terms,
                    accept: form_accept
                }

                await axios.post(props.ip_address+'/backend/financeteams/api/form_contact', data);
                
                form_set_sent(true);
                form_set_loading(false);
            }
            
            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();
        
        event.preventDefault();
    }

    let form_terms_class = 'content-form-checkbox';
    if (form_terms === true)
        form_terms_class = 'content-form-checkbox-active';
    
    let form = '';
    if (form_sent === false)
    {
        if (form_loading === false)
        {
            form = (
                <form onSubmit={event => handleSubmit(event)}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <input
                                className="content-form-singleline"
                                name="form_name"
                                type="text"
                                maxLength="128"
                                value={form_name}
                                autoComplete="off"
                                onChange={event => form_set_name(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data, 'contactform_name', props.debug)+' *'}
                                required />
                        </div>
                        <div className="col-12 col-md-4">
                            <input
                                className="content-form-singleline"
                                name="form_phone"
                                type="text"
                                maxLength="128"
                                value={form_phone}
                                autoComplete="off"
                                onChange={event => form_set_phone(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data, 'contactform_phone', props.debug)+' *'}
                                required />
                        </div>
                        <div className="col-12 col-md-4">
                            <input
                                className="content-form-singleline"
                                name="form_email"
                                type="text"
                                maxLength="128"
                                value={form_email}
                                autoComplete="off"
                                onChange={event => form_set_email(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data,'contactform_email', props.debug)+' *'} 
                                required />
                        </div>
                        <div className="col-12">
                            <textarea
                                className="content-form-multiline"
                                name="form_description"
                                maxLength="4096"
                                value={form_description}
                                onChange={event => form_set_description(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data,'contactform_description', props.debug)} />
                        </div>
                        <div className="col-12">
                            <div className="content-form-checkbox-container">
                                <label className={form_terms_class}>
                                    <input
                                        name="form_terms"
                                        type="checkbox"
                                        checked={form_terms}
                                        onChange={event => form_set_terms(event.target.checked)}
                                        required />
                                    <span className="content-form-text">{UtilTranslate(props.translation_data, 'contactform_terms', props.debug)} <a href={UtilRoute(props.translation_language, props.routes_urls, 'privacy', null)} target="_blank" rel="noopener noreferrer">{UtilTranslate(props.translation_data, 'contactform_privacy', props.debug)}</a>. *</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <input
                                className="content-form-accept"
                                name="form_accept"
                                type="checkbox"
                                checked={form_accept}
                                onChange={event => form_set_accept(event.target.checked)} />
                        </div>
                        <div className="col-12">
                            <button onClick = {() => {}} className="content-form-send">{UtilTranslate(props.translation_data, 'contactform_send', props.debug)}<div className="content-form-send-icon"></div></button>
                        </div>
                    </div>
                </form>
            );
        }
        else
        {
            if (form_error === true)
            {
                form = (
                    <div>
                        {UtilTranslate(props.translation_data, 'contactform_error', props.debug)}
                    </div>
                );
            }
        }
    }
    else
    {
        form = (
            <div>
                {UtilTranslate(props.translation_data, 'contactform_complete', props.debug)}
            </div>
        );
    }

    let loading = '';
    if (form_loading === true)
    {
        loading = (
            <div className="content-form-loading"></div>
        );
    }

    return (
        <React.Fragment>
            <section className="content-form-section" id="contactform">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-form-header">
                                <div className="d-block d-lg-none">
                                    <h2 className="content-form-title-mobile">{UtilTranslate(props.translation_data, 'contactform_title', props.debug)}</h2>
                                </div>
                                <div className="d-none d-lg-block">
                                    <h2 className="content-form-title-desktop">{UtilTranslate(props.translation_data, 'contactform_title', props.debug)}</h2>
                                </div>

                                { content_data !== null &&
                                    <div className="text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'formcontact', props.debug)}}></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    {loading}
                    {form}
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language
function SectionFormVacancy(props)
{
    const {vacancy_slug} = useParams();

    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'formvacancy'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text

                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);
    
    // Form
    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);
    
    const handleSubmit = (event) => {
        form_set_loading(true);
        
        const post = async () => {
            try
            {
                let form_description_corrected = form_description;
                if (form_description_corrected === '')
                    form_description_corrected = '-';

                const data = {
                    name: form_name,
                    phone: form_phone,
                    email: form_email,
                    description: form_description_corrected,
                    terms: form_terms,
                    accept: form_accept,
                    slug: vacancy_slug
                }

                await axios.post(props.ip_address+'/backend/financeteams/api/form_vacancy', data);
                
                form_set_sent(true);
                form_set_loading(false);
            }
            
            catch (error)
            {
                form_set_error(true);
                form_set_loading(false);

                console.log(error);
            }
        }

        post();
        
        event.preventDefault();
    }

    let form_terms_class = 'content-form-checkbox';
    if (form_terms === true)
        form_terms_class = 'content-form-checkbox-active';

    let form = '';
    if (form_sent === false)
    {
        if (form_loading === false)
        {
            form = (
                <form onSubmit={event => handleSubmit(event)}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <input
                                className="content-form-singleline"
                                name="form_name"
                                type="text"
                                maxLength="128"
                                value={form_name}
                                autoComplete="off"
                                onChange={event => form_set_name(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data, 'contactform_name', props.debug)+' *'}
                                required />
                        </div>
                        <div className="col-12 col-md-4">
                            <input
                                className="content-form-singleline"
                                name="form_phone"
                                type="text"
                                maxLength="128"
                                value={form_phone}
                                autoComplete="off"
                                onChange={event => form_set_phone(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data, 'contactform_phone', props.debug)+' *'}
                                required />
                        </div>
                        <div className="col-12 col-md-4">
                            <input
                                className="content-form-singleline"
                                name="form_email"
                                type="text"
                                maxLength="128"
                                value={form_email}
                                autoComplete="off"
                                onChange={event => form_set_email(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data,'contactform_email', props.debug)+' *'} 
                                required />
                        </div>
                        <div className="col-12">
                            <textarea
                                className="content-form-multiline"
                                name="form_description"
                                maxLength="4096"
                                value={form_description}
                                onChange={event => form_set_description(event.target.value)}
                                placeholder={UtilTranslate(props.translation_data,'contactform_description', props.debug)} />
                        </div>
                        <div className="col-12">
                            <div className="content-form-checkbox-container">
                                <label className={form_terms_class}>
                                    <input
                                        name="form_terms"
                                        type="checkbox"
                                        checked={form_terms}
                                        onChange={event => form_set_terms(event.target.checked)}
                                        required />
                                    <span className="content-form-text">{UtilTranslate(props.translation_data, 'contactform_terms', props.debug)} <a href={UtilRoute(props.translation_language, props.routes_urls, 'privacy', null)} target="_blank" rel="noopener noreferrer">{UtilTranslate(props.translation_data, 'contactform_privacy', props.debug)}</a>. *</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <input
                                className="content-form-accept"
                                name="form_accept"
                                type="checkbox"
                                checked={form_accept}
                                onChange={event => form_set_accept(event.target.checked)} />
                        </div>
                        <div className="col-12">
                            <button onClick = {() => {}} className="content-form-send">{UtilTranslate(props.translation_data, 'contactform_send', props.debug)}<div className="content-form-send-icon"></div></button>
                        </div>
                    </div>
                </form>
            );
        }
        else
        {
            if (form_error === true)
            {
                form = (
                    <div>
                        {UtilTranslate(props.translation_data, 'contactform_error', props.debug)}
                    </div>
                );
            }
        }
    }
    else
    {
        form = (
            <div>
                {UtilTranslate(props.translation_data, 'contactform_complete', props.debug)}
            </div>
        );
    }

    let loading = '';
    if (form_loading === true)
    {
        loading = (
            <div className="content-form-loading"></div>
        );
    }

    return (
        <React.Fragment>
            <section className="content-form-section" id="contactform">
                <div className="container">
                    <div className="row">
                        { /*
                        <div className="col-12 d-block d-md-none">    
                            <div className="content-form-header">
                                <ElementTitleH2 text={UtilTranslate(props.translation_data, 'vacancyform_title', props.debug)} />
                                <p className="text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(UtilTranslate(props.translation_data,'vacancyform_text', props.debug))}}></p>
                            </div>
                        </div>
                        
                        <div className="col-12 d-none d-md-block">
                        */ }
                        <div className="col-12">
                            <div className="content-form-header">
                                <ElementTitleH2Small text={UtilTranslate(props.translation_data, 'vacancyform_title', props.debug)} />

                                { content_data !== null &&
                                    <div className="text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'formvacancy', props.debug)}}></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    {loading}
                    {form}
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionHowwework(props)
{   
    return (
        <React.Fragment>
            <div className="content-howwework-office">
                <div className="content-howwework-office-outro"></div>
            </div>
            <section className="content-section-howwework">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-block d-lg-none">
                                <h1 className="content-howwework-title-mobile">{UtilTranslate(props.translation_data, 'content_howwework_title', props.debug)}</h1>
                                <div className="content-howwework-subtitle-mobile">{UtilTranslate(props.translation_data, 'content_howwework_subtitle', props.debug)}</div>
                            </div>
                            <div className="d-none d-lg-block">
                                <h1 className="content-howwework-title-desktop">{UtilTranslate(props.translation_data, 'content_howwework_title', props.debug)}</h1>
                                <div className="content-howwework-subtitle-desktop">{UtilTranslate(props.translation_data, 'content_howwework_subtitle', props.debug)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row content-howwework-curves-container">
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="content-howwework-curves">
                                <div className="content-howwework-curves-icon-container">
                                    <div className="content-howwework-curves-icon-needs"></div>
                                </div>
                                <div className="content-howwework-curves-title">{UtilTranslate(props.translation_data, 'content_howwework_curve_title1', props.debug)}</div>
                                <p className="content-howwework-curves-text">{UtilTranslate(props.translation_data, 'content_howwework_curve_text1', props.debug)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-none d-md-block">
                        <div className="col-12">
                            <div className="content-howwework-curve"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-6"></div>
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="content-howwework-curves">
                                <div className="content-howwework-curves-icon-container">
                                    <div className="content-howwework-curves-icon-selection"></div>
                                </div>
                                <div className="content-howwework-curves-title">{UtilTranslate(props.translation_data, 'content_howwework_curve_title2', props.debug)}</div>
                                <p className="content-howwework-curves-text">{UtilTranslate(props.translation_data, 'content_howwework_curve_text2', props.debug)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-none d-md-block">
                        <div className="col-12">
                            <div className="content-howwework-curve-mirror"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="content-howwework-curves">
                                <div className="content-howwework-curves-icon-container">
                                    <div className="content-howwework-curves-icon-meeting"></div>
                                </div>
                                <div className="content-howwework-curves-title">{UtilTranslate(props.translation_data, 'content_howwework_curve_title3', props.debug)}</div>
                                <p className="content-howwework-curves-text">{UtilTranslate(props.translation_data, 'content_howwework_curve_text3', props.debug)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-none d-md-block">
                        <div className="col-12">
                            <div className="content-howwework-curve"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-6"></div>
                        <div className="col-12 col-md-9 col-lg-6">
                            <div className="content-howwework-curves">
                                <div className="content-howwework-curves-icon-container">
                                    <div className="content-howwework-curves-icon-partnership"></div>
                                </div>
                                <div className="content-howwework-curves-title">{UtilTranslate(props.translation_data, 'content_howwework_curve_title4', props.debug)}</div>
                                <p className="content-howwework-curves-text">{UtilTranslate(props.translation_data, 'content_howwework_curve_text4', props.debug)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="content-howwework-quote-container">
                                <div className="content-howwework-quote-icon"></div>
                                <div className="content-howwework-quote">{UtilTranslate(props.translation_data, 'content_howwework_quote', props.debug)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-howwework-intro"></div>
            <section className="content-section-howwework2">
                <div className="container">
                    <div className="row content-howwework-container">
                        <div className="col-12 col-md-6">
                            <div className="content-howwework-item-container">
                                <div className="content-howwework-item-title-container">
                                    <div className="content-howwework-item-icon-stack"></div>
                                    <div className="content-howwework-item-title">
                                        {UtilTranslate(props.translation_data, 'content_howwework_title1', props.debug)}
                                    </div>
                                </div>
                                <div className="content-howwework-item-text">
                                    {UtilTranslate(props.translation_data, 'content_howwework_text1', props.debug)}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="content-howwework-item-container">
                                <div className="content-howwework-item-title-container">
                                    <div className="content-howwework-item-icon-travel"></div>
                                    <div className="content-howwework-item-title">
                                        {UtilTranslate(props.translation_data, 'content_howwework_title2', props.debug)}
                                    </div>
                                </div>
                                <div className="content-howwework-item-text">
                                    {UtilTranslate(props.translation_data, 'content_howwework_text2', props.debug)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-howwework-outro"></div>
        </React.Fragment>
    );
}

// Props: debug, routes_urls, translation_data, translation_language
function SectionNavigation(props)
{
    // Expand (mobile)
    const [expanded, set_expanded] = useState(false);
    
    // Routes
    const [route_nl, route_set_nl] = useState('/nl-NL');
    const [route_en, route_set_en] = useState('/en-GB');
    const [route_bs, route_set_bs] = useState('/bs-BA');
    
    const location = useLocation();

    let debug = props.debug;
    let routes_urls = props.routes_urls;
    let translation_language = props.translation_language;
    useEffect(() => {
        if (translation_language !== null)
        {
            let url_split = location['pathname'].split('/');

            if (typeof url_split[2] === 'undefined')
            {
                // Default
                route_set_nl('/nl-NL');
                route_set_en('/en-GB');
                route_set_bs('/bs-BA');
            }
            else
            {
                for (let route in routes_urls[translation_language])
                {
                    if (routes_urls[translation_language][route] === url_split[2])
                    {
                        if (debug === true)
                        {
                            console.log(routes_urls['nl-NL'][route]);
                            console.log(routes_urls['en-GB'][route]);
                            console.log(routes_urls['bs-BA'][route]);
                        }

                        let new_route_nl = '/nl-NL/'+routes_urls['nl-NL'][route];
                        let new_route_en = '/en-GB/'+routes_urls['en-GB'][route];
                        let new_route_bs = '/bs-BA/'+routes_urls['bs-BA'][route];

                        // Add slug
                        if (typeof url_split[3] !== 'undefined')
                        {
                            new_route_nl += '/'+url_split[3];
                            new_route_en += '/'+url_split[3];
                            new_route_bs += '/'+url_split[3];
                        }

                        route_set_nl(new_route_nl);
                        route_set_en(new_route_en);
                        route_set_bs(new_route_bs);
                    }
                }
            }
        }
    }, [debug, location, routes_urls, translation_language]);

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container">
                    <Navbar expanded={expanded} expand="lg" variant="light" className="navbar-container">
                        <Link to={'/'+props.translation_language} className="navbar-title" onClick={() => set_expanded(false)}></Link>

                        <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="$navbarScroll" onClick={() => set_expanded(expanded ? false : 'expanded')} />
                        <Navbar.Collapse id="navbarScroll" className="navbar-justify-right">
                            <Nav>
                                { /* Multilanguage desktop */ }
                                <div className="d-none d-lg-block">
                                    <div className="navbar-item-multilanguage-container">
                                        { props.translation_language === 'nl-NL' &&
                                            <div className="navbar-item-multilanguage-item-container">
                                                <div className="navbar-item-multilanguage-item">
                                                    <div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_nl', props.debug)}
                                                </div>
                                            </div>
                                        }

                                        { props.translation_language === 'en-GB' &&
                                            <div className="navbar-item-multilanguage-item-container">
                                                <div className="navbar-item-multilanguage-item">
                                                    <div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_en', props.debug)}
                                                </div>
                                            </div>
                                        }

                                        { props.translation_language === 'bs-BA' &&
                                            <div className="navbar-item-multilanguage-item-container">
                                                <div className="navbar-item-multilanguage-item">
                                                    <div className="navbar-item-multilanguage-flag-bs"></div>{UtilTranslate(props.translation_data, 'navigation_bs', props.debug)}
                                                </div>
                                            </div>
                                        }

                                        <div className="navbar-item-multilanguage-dropdown">
                                            { props.translation_language !== null && props.translation_language !== 'nl-NL' &&
                                                <div className="navbar-item-multilanguage-dropdown-item-container">
                                                    <Link to={route_nl} className="navbar-item-multilanguage-dropdown-item" onClick={() => set_expanded(false)}>
                                                        <div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_nl', props.debug)}
                                                    </Link>
                                                </div>
                                            }

                                            { props.translation_language !== null && props.translation_language !== 'en-GB' &&
                                                <div className="navbar-item-multilanguage-dropdown-item-container">
                                                    <Link to={route_en} className="navbar-item-multilanguage-dropdown-item" onClick={() => set_expanded(false)}>
                                                        <div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_en', props.debug)}
                                                    </Link>
                                                </div>
                                            }

                                            { props.translation_language !== null && props.translation_language !== 'bs-BA' &&
                                                <div className="navbar-item-multilanguage-dropdown-item-container">
                                                    <Link to={route_bs} className="navbar-item-multilanguage-dropdown-item" onClick={() => set_expanded(false)}>
                                                        <div className="navbar-item-multilanguage-flag-bs"></div>{UtilTranslate(props.translation_data, 'navigation_bs', props.debug)}
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                
                                { props.translation_language !== null &&
                                    <React.Fragment>
                                        <div className="navbar-listitem">
                                            <Link to={UtilRoute(props.translation_language, props.routes_urls, 'howwework', null)} className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_howwework', props.debug)}</Link>
                                        </div>

                                        <div className="navbar-listitem">
                                            <Link to={UtilRoute(props.translation_language, props.routes_urls, 'onboarding', null)} className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_onboarding', props.debug)}</Link>
                                        </div>

                                        <div className="navbar-listitem">
                                            <Link to={UtilRoute(props.translation_language, props.routes_urls, 'article_overview', null)} className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_articles', location)}</Link>
                                        </div>
                                        
                                        <div className="navbar-listitem">
                                            <Link to={UtilRoute(props.translation_language, props.routes_urls, 'about', null)} className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_about', props.debug)}</Link>
                                        </div>
                                        
                                        <div className="navbar-listitem">
                                            <Link to={UtilRoute(props.translation_language, props.routes_urls, 'vacancy_overview', null)} className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_vacancies', props.debug)}</Link>
                                        </div>

                                        <div className="navbar-listitem">
                                            <HashLink smooth to="#contactform" className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_contactform', props.debug)}</HashLink>
                                        </div>

                                        { /* Multilanguage mobile */ }

                                        { props.translation_language !== null && props.translation_language !== 'nl-NL' &&
                                            <div className="navbar-listitem d-block d-lg-none">
                                                <Link to={route_nl} className="navbar-item" onClick={() => set_expanded(false)}><div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_switch_nl', props.debug)}</Link>
                                            </div>
                                        }

                                        { props.translation_language !== null && props.translation_language !== 'en-GB' &&
                                            <div className="navbar-listitem d-block d-lg-none">
                                                <Link to={route_en}  className="navbar-item" onClick={() => set_expanded(false)}><div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_switch_en', props.debug)}</Link>
                                            </div>
                                        }

                                        { props.translation_language !== null && props.translation_language !== 'bs-BA' &&
                                            <div className="navbar-listitem d-block d-lg-none">
                                                <Link to={route_bs}  className="navbar-item" onClick={() => set_expanded(false)}><div className="navbar-item-multilanguage-flag-bs"></div>{UtilTranslate(props.translation_data, 'navigation_switch_bs', props.debug)}</Link>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </section>
            <div className="navbar-spacing"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data
function SectionOnboarding(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'onboarding',
                            'onboardingdino',
                            'onboardingdennis'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="content-section-standard">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1 text={UtilTranslate(props.translation_data, 'content_onboarding_title', props.debug)} />

                            { content_data !== null &&
                                <div className="text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'onboarding', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-about-profile-intro"></div>
            <section className="content-section-about-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="content-about-profile-photo-dennis"></div>
                            <div className="content-about-profile-name">Dennis Wenders</div>
                            <div className="content-about-profile-title">{UtilTranslate(props.translation_data, 'content_about_titledennis', props.debug)}</div>

                            <div className="content-about-profile-quote-container">
                                { content_data !== null &&
                                    <div className="content-about-profile-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[2]), 'onboardingdennis', props.debug)}}></div>
                                }
                            </div>
                        </div>
                        { /*
                        <div className="col-12 col-md-6">
                            <div className="content-about-profile-photo-dino"></div>
                            <div className="content-about-profile-name">Dino Kuburić</div>
                            <div className="content-about-profile-title">{UtilTranslate(props.translation_data, 'content_about_titledino', props.debug)}</div>

                            <div className="content-about-profile-quote-container">
                                { content_data !== null &&
                                    <div className="content-about-profile-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[1]), 'onboardingdino', props.debug)}}></div>
                                }
                            </div>
                        </div>
                        */ }
                    </div>
                </div>
            </section>
            <div className="content-about-profile-outro"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionOnboardingIntro(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'onboardingintro',
                        ],
                        language: translation_language
                    };
                    
                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text

                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <section className="content-section-onboarding">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-block d-lg-none">
                        <ElementTitleH2
                            text={UtilTranslate(props.translation_data, 'content_onboardingintro_title', props.debug)} />

                        <img src="/image/photo_onboarding2.jpg" alt="Onboarding" width="100%" />

                        { content_data !== null &&
                            <div className="content-onboarding-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'homepageonboarding', props.debug)}}></div>
                        }

                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'onboarding', null)}>
                            <div className="content-onboarding-button-container">
                                <div className="content-onboarding-button">
                                    {UtilTranslate(props.translation_data, 'navigation_onboarding', props.debug)}
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-6 d-none d-lg-block">
                        <ElementTitleH2
                            text={UtilTranslate(props.translation_data, 'content_onboardingintro_title', props.debug)} />

                        { content_data !== null &&
                            <div className="content-onboarding-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'homepageonboarding', props.debug)}}></div>
                        }

                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'onboarding', null)}>
                            <div className="content-onboarding-button">
                                {UtilTranslate(props.translation_data, 'navigation_onboarding', props.debug)}
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                        <img src="/image/photo_onboarding2.jpg" alt="Onboarding" width="100%" />
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionPrivacy(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'privacy'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="content-section-standard">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'navigation_privacy', props.debug)} />
        
                            { content_data !== null &&
                                <div className="text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionMeettheteam(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'meettheteam',
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text

                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <div className="content-meettheteam-intro"></div>
            <section className="content-section-meettheteam">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-block d-lg-none">
                                <h2 className="content-meettheteam-title-mobile">{UtilTranslate(props.translation_data, 'content_meettheteam_title', props.debug)}</h2>
                                <div className="content-meettheteam-subtitle-mobile">{UtilTranslate(props.translation_data, 'content_meettheteam_subtitle', props.debug)}
                                </div>
                            </div>
                            <div className="d-none d-lg-block">
                                <h2 className="content-meettheteam-title-desktop">{UtilTranslate(props.translation_data, 'content_meettheteam_title', props.debug)}</h2>
                                <div className="content-meettheteam-subtitle-desktop">{UtilTranslate(props.translation_data, 'content_meettheteam_subtitle', props.debug)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-12 col-lg-8">
                            { content_data !== null &&
                                <React.Fragment>
                                    <img src="/image/photo_meettheteam.jpg" alt="Meet the team" width="100%" />

                                    <div className="content-meettheteam-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'teamsarajevo', props.debug)}}></div>

                                    <div className="content-meettheteam-button-container">
                                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'about', null)}>
                                            <div className="content-meettheteam-button">{UtilTranslate(props.translation_data, 'navigation_about', props.debug)}</div>
                                        </Link>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-meettheteam-outro"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language
function SectionVacancy(props)
{
    const {vacancy_slug} = useParams();
    const [vacancy_data, vacancy_set_data] = useState(null);

    const navigate = useNavigate();

    // Vacancy
    let debug = props.debug;
    let ip_address = props.ip_address;
    let routes_urls = props.routes_urls;
    let translation_language = props.translation_language;
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        slug: vacancy_slug
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/vacancy', { params });
                    
                    vacancy_set_data(response.data);
                    // <str> title
                    // <str> location
                    // <str> education
                    // <str> hoursperweek
                    // <str> schedule
                    // <str> text_intro
                    // <str> text_description
                    // <str> text_aboutyou
                                    
                    if (debug === true)
                        console.log(response.data);
                }

                catch (error)
                {
                    navigate(UtilRoute(translation_language, routes_urls, 'vacancy_overview', null))
                }
            }

            get();
        }
    }, [debug, ip_address, vacancy_slug, translation_language, routes_urls, navigate]);

    return (
        <React.Fragment>
            <section className="content-section-vacancy">
                <div className="container">
                    { (props.translation_language === 'nl-NL' || props.translation_language === 'bs-BA') &&
                        <div className="row">
                            <div className="col-12">
                                <div className="content-vacancy-language">
                                    <div className="content-vacancy-language-flag-en"></div>{UtilTranslate(props.translation_data, 'content_info_englishcontent', props.debug)}
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        { vacancy_data !== null &&
                            <div className="col-12">
                                <h1 className="content-vacancy-title">{vacancy_data[0]}</h1>
                            </div>
                        }

                        { vacancy_data === null &&
                            <div className="col-12">
                                <div className="loading"></div>
                            </div>
                        }
                    </div>
                        
                    { vacancy_data !== null &&
                        <React.Fragment>
                            <div className="content-vacancy-container">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="content-vacancies-icons-container">
                                            <div className="content-vacancies-icon-marker"></div>
                                            <div className="content-vacancies-icons-title">{vacancy_data[1]}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="content-vacancies-icons-container">
                                            <div className="content-vacancies-icon-education"></div>
                                            <div className="content-vacancies-icons-title">{vacancy_data[2]}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="content-vacancies-icons-container">
                                            <div className="content-vacancies-icon-clock"></div>
                                            <div className="content-vacancies-icons-title">
                                            {vacancy_data[3]}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="content-vacancies-icons-container">
                                            <div className="content-vacancies-icon-schedule"></div>
                                            <div className="content-vacancies-icons-title">{vacancy_data[4]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <ElementTitleH2Small
                                    text={UtilTranslate(props.translation_data, 'content_vacancy_description', props.debug)} />
                                <div className="content-vacancy-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(vacancy_data[5])}}></div>
                                
                                <ElementTitleH2Small
                                    text={UtilTranslate(props.translation_data, 'content_vacancy_tasks', props.debug)} />
                                <div className="content-vacancy-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(vacancy_data[6])}}></div>

                                <ElementTitleH2Small
                                    text={UtilTranslate(props.translation_data, 'content_vacancy_aboutyou', props.debug)} />
                                <div className="content-vacancy-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(vacancy_data[7])}}></div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language, upload_images
function SectionVacancyOverview(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'vacancies'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/financeteams/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    // Photos
    const [photo_vacancies_data, photo_vacancies_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address+'/backend/financeteams/api/photo_vacancies');

                photo_vacancies_set_data(response.data);
                // <str> photo

                if (debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [debug, ip_address]);

    let photos = [];
    if (photo_vacancies_data !== null)
    {
        for (let i = 0; i < parseInt(photo_vacancies_data.length); i++)
        {
            photos.push(
                <div key={i} className="content-vacancies-photo-container">
                    <img className="content-vacancies-photo" alt="Working at" src={props.upload_images+'/'+photo_vacancies_data[i][0]} />
                </div>
            );
        }
    }

    // Vacancies
    const [vacancy_data, vacancy_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/financeteams/api/vacancy_overview');

                vacancy_set_data(response.data);
                // <str> title
                // <str> slug
                // <str> location
                // <str> education
                // <str> hoursperweek
                // <str> schedule
                // <str> text_preview

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let vacancies = [];
    if (vacancy_data !== null)
    {
        for (let i = 0; i < parseInt(vacancy_data.length); i++)
        {
            vacancies.push(
                <React.Fragment key={i}>
                    <ElementVacancyPreview
                        button={UtilTranslate(props.translation_data, 'content_vacancy_button', props.debug)}
                        education={vacancy_data[i][3]}
                        hoursperweek={vacancy_data[i][4]}
                        location={vacancy_data[i][2]}
                        schedule={vacancy_data[i][5]}
                        text={vacancy_data[i][6]}
                        title={vacancy_data[i][0]}
                        url={UtilRoute(props.translation_language, props.routes_urls, 'vacancy', vacancy_data[i][1])} />
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <section className="content-section-standard">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'content_vacancy_title', props.debug)} />
        
                            { content_data !== null &&
                                <div className="text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'vacancies', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="content-section-vacancies-list">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="content-vacancies-item-container">
                                <div className="content-vacancies-item-title">{UtilTranslate(props.translation_data, 'content_vacancyoverview_title1', props.debug)}</div>
                                <div className="content-vacancies-item-text">{UtilTranslate(props.translation_data, 'content_vacancyoverview_text1', props.debug)}</div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="content-vacancies-item-container">
                                <div className="content-vacancies-item-title">{UtilTranslate(props.translation_data, 'content_vacancyoverview_title2', props.debug)}</div>
                                <div className="content-vacancies-item-text">{UtilTranslate(props.translation_data, 'content_vacancyoverview_text2', props.debug)}</div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="content-vacancies-item-container">
                                <div className="content-vacancies-item-title">{UtilTranslate(props.translation_data, 'content_vacancyoverview_title3', props.debug)}</div>
                                <div className="content-vacancies-item-text">{UtilTranslate(props.translation_data, 'content_vacancyoverview_text3', props.debug)}</div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="content-vacancies-item-container">
                                <div className="content-vacancies-item-title">{UtilTranslate(props.translation_data, 'content_vacancyoverview_title4', props.debug)}</div>
                                <div className="content-vacancies-item-text">{UtilTranslate(props.translation_data, 'content_vacancyoverview_text4', props.debug)}</div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="content-vacancies-item-container">
                                <div className="content-vacancies-item-title">{UtilTranslate(props.translation_data, 'content_vacancyoverview_title5', props.debug)}</div>
                                <div className="content-vacancies-item-text">{UtilTranslate(props.translation_data, 'content_vacancyoverview_text5', props.debug)}</div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="content-vacancies-item-container">
                                <div className="content-vacancies-item-title">{UtilTranslate(props.translation_data, 'content_vacancyoverview_title6', props.debug)}</div>
                                <div className="content-vacancies-item-text">{UtilTranslate(props.translation_data, 'content_vacancyoverview_text6', props.debug)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="content-vacancies-carousel">
                                { photos.length > 0 &&
                                    <React.Fragment>
                                        <div className="d-block d-sm-none">
                                            <Carousel
                                                slidesToShow={1}
                                                cellSpacing="30"
                                                renderCenterLeftControls={({ previousSlide, previousDisabled }) => previousDisabled ? null : (
                                                    <button className="content-vacancies-carousel-button-left" onClick={previousSlide}>
                                                        <div className="content-vacancies-carousel-chevron-left"></div>
                                                    </button>
                                                )}
                                                renderCenterRightControls={({ nextSlide, nextDisabled}) => nextDisabled ? null : (
                                                    <button className="content-vacancies-carousel-button-right" onClick={nextSlide}>
                                                        <div className="content-vacancies-carousel-chevron-right"></div>
                                                    </button>
                                                )} >
                                                
                                                {photos}
                                            </Carousel>
                                        </div>
                                        <div className="d-none d-sm-block d-lg-none">
                                            <Carousel
                                                slidesToShow={2}
                                                cellSpacing="30"
                                                renderCenterLeftControls={({ previousSlide, previousDisabled }) => previousDisabled ? null : (
                                                    <button className="content-vacancies-carousel-button-left" onClick={previousSlide}>
                                                        <div className="content-vacancies-carousel-chevron-left"></div>
                                                    </button>
                                                )}
                                                renderCenterRightControls={({ nextSlide, nextDisabled}) => nextDisabled ? null : (
                                                    <button className="content-vacancies-carousel-button-right" onClick={nextSlide}>
                                                        <div className="content-vacancies-carousel-chevron-right"></div>
                                                    </button>
                                                )} >
                                                
                                                {photos}
                                            </Carousel>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <Carousel
                                                slidesToShow={3}
                                                cellSpacing="30"
                                                renderCenterLeftControls={({ previousSlide, previousDisabled }) => previousDisabled ? null : (
                                                    <button className="content-vacancies-carousel-button-left" onClick={previousSlide}>
                                                        <div className="content-vacancies-carousel-chevron-left"></div>
                                                    </button>
                                                )}
                                                renderCenterRightControls={({ nextSlide, nextDisabled}) => nextDisabled ? null : (
                                                    <button className="content-vacancies-carousel-button-right" onClick={nextSlide}>
                                                        <div className="content-vacancies-carousel-chevron-right"></div>
                                                    </button>
                                                )} >
                                                
                                                {photos}
                                            </Carousel>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="content-vacancies-intro"></div>
            <section className="content-section-vacancies">
                <div className="container">
                    <div className="row">
                        <div className="d-block d-lg-none">
                            <h2 className="content-vacancies-overview-title-mobile">{UtilTranslate(props.translation_data, 'content_vacancies_title', props.debug)}</h2>
                        </div>
                        <div className="d-none d-lg-block">
                            <h2 className="content-vacancies-overview-title-desktop">{UtilTranslate(props.translation_data, 'content_vacancies_title', props.debug)}</h2>
                        </div>

                        { vacancy_data === null &&
                            <div className="col-12">
                                <div className="loading"></div>
                            </div>
                        }

                        {vacancies}
                    </div>
                </div>
            </section>
            <div className="content-vacancies-outro"></div>
            
        </React.Fragment>
    );
}

// Props: debug, translation_data, translation_language
function SectionVlaai(props)
{
    return (
        <React.Fragment>
            <section className="content-section-video">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-form-header">
                                <div className="d-block d-lg-none">
                                    <h2 className="content-form-title-mobile">{UtilTranslate(props.translation_data, 'vlaai_title', props.debug)}</h2>
                                </div>
                                <div className="d-none d-lg-block">
                                    <h2 className="content-form-title-desktop">{UtilTranslate(props.translation_data, 'vlaai_title', props.debug)}</h2>
                                </div>
                                <div className="text text-center">{UtilTranslate(props.translation_data, 'vlaai_text', props.debug)} <a href="https://www.youtube.com/watch?v=zLz3mIadCDo" target="_blank" rel="noopener noreferrer" className="body-link">https://www.youtube.com/watch?v=zLz3mIadCDo</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="d-block d-sm-none">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/zLz3mIadCDo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            
                            <div className="d-none d-sm-block d-md-none">
                                <iframe width="540" height="304" src="https://www.youtube.com/embed/zLz3mIadCDo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>

                            <div className="d-none d-md-block d-lg-none">
                                <iframe width="720" height="405" src="https://www.youtube.com/embed/zLz3mIadCDo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>

                            <div className="d-none d-lg-block d-xl-none">
                                <iframe width="960" height="540" src="https://www.youtube.com/embed/zLz3mIadCDo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>

                            <div className="d-none d-xl-block">
                                <iframe width="1140" height="641" src="https://www.youtube.com/embed/zLz3mIadCDo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="text" style={{'marginTop': '24px'}}>{UtilTranslate(props.translation_data, 'vlaai_text2', props.debug)}
                            </div>
                            <div style={{'marginTop': '24px'}}>
                                <a href="/nl-NL/hoe-wij-werken">
                                    <div className="banner-button">
                                        {UtilTranslate(props.translation_data, 'navigation_howwework', props.debug)}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: translation_data
function SectionWhatwedo(props)
{
    return (
        <React.Fragment>
            <section className="content-section-whatwedo">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="content-whatwedo-item-container">
                                <div className="content-whatwedo-item-title-container">
                                    <div className="content-whatwedo-item-icon-growth"></div>
                                    <div className="content-whatwedo-item-title">
                                        {UtilTranslate(props.translation_data, 'content_whatwedo_title1', props.debug)}
                                    </div>
                                </div>
                                <div className="content-whatwedo-item-text">
                                    {UtilTranslate(props.translation_data, 'content_whatwedo_text1', props.debug)}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="content-whatwedo-item-container">
                                <div className="content-whatwedo-item-title-container">
                                    <div className="content-whatwedo-item-icon-checkmarkcircle"></div>
                                    <div className="content-whatwedo-item-title">    
                                        {UtilTranslate(props.translation_data, 'content_whatwedo_title2', props.debug)}
                                    </div>
                                </div>
                                <div className="content-whatwedo-item-text">
                                    {UtilTranslate(props.translation_data, 'content_whatwedo_text2', props.debug)}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="content-whatwedo-item-container">
                                <div className="content-whatwedo-item-title-container">
                                    <div className="content-whatwedo-item-icon-heart"></div>
                                    <div className="content-whatwedo-item-title">
                                        {UtilTranslate(props.translation_data, 'content_whatwedo_title3', props.debug)}
                                    </div>
                                </div>
                                <div className="content-whatwedo-item-text">
                                    {UtilTranslate(props.translation_data, 'content_whatwedo_text3', props.debug)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export { SectionAbout, SectionArticle, SectionArticleOverview, SectionArticleProfiles, SectionArticleQuotes, SectionArticleRelated, SectionBanner, SectionDisclaimer, SectionFooter, SectionFormContact, SectionFormVacancy, SectionHowwework, SectionNavigation, SectionOnboarding, SectionOnboardingIntro, SectionPrivacy, SectionMeettheteam, SectionVacancy, SectionVacancyOverview, SectionVlaai, SectionWhatwedo };