import React from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

// Props: button, date, image, title, url
function ElementArticlePreview(props)
{
    return (
        <Link to={props.url}>
            <div className="content-article-preview-animation">
                <div className="content-article-preview-container">
                    { props.image !== null &&
                        <div className="content-article-preview-photo" style={{backgroundImage: 'url('+props.image+')'}}></div>
                    }

                    <div className="content-article-preview-bottom">
                        <div className="d-block d-xl-none">
                            <div className="content-article-preview-title-mobile">{props.title}</div>
                        </div>
                        <div className="d-none d-xl-block">
                            <div className="content-article-preview-title-desktop">{props.title}</div>
                        </div>

                        <div className="content-article-preview-date">{props.date}</div>

                        <div className="content-article-preview-button-container">
                            <div className="content-article-preview-button">{props.button}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

// Props: button, date, image, title, url
function ElementArticlePreview2(props)
{
    return (
        <Link to={props.url}>
            <div className="content-article-preview-animation">
                <div className="content-article-preview-container">
                    { props.image !== null &&
                        <div className="content-article-preview-photo" style={{backgroundImage: 'url('+props.image+')'}}></div>
                    }

                    <div className="content-article-preview2-bottom">
                        <div className="d-block d-xl-none">
                            <div className="content-article-preview2-title-mobile">{props.title}</div>
                        </div>
                        <div className="d-none d-xl-block">
                            <div className="content-article-preview2-title-desktop">{props.title}</div>
                        </div>
                        <div className="content-article-preview2-date">{props.date}</div>

                        <div className="content-article-preview-button-container">
                            <div className="content-article-preview-button">{props.button}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

// Props: button, image, name, quote, url
function ElementArticleQuotePreview(props)
{
    return (
        <Link to={props.url}>
            <div className="content-articlequote-animation">
                <div className="content-articlequote-container">
                    <div className="content-articlequote-photo-container">
                        <div className="content-articlequote-photo" style={{backgroundImage: 'url('+props.image+')'}}></div>
                        <div className="content-articlequote-name">{props.name}</div>
                    </div>
                    
                    <div className="content-articlequote-quote-container">
                        <div className="content-articlequote-quote-icon"></div>
                        <div className="content-articlequote-quote">{props.quote}</div>
                    </div>

                    <div className="content-articlequote-button-container">
                        <div className="content-articlequote-button">{props.button}</div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

// Props: ip_address
function ElementDebugResponsive(props)
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href={props.ip_address+'/backend/financeteams/admin'} target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href={props.ip_address+'/backend/financeteams/admin'} target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href={props.ip_address+'/backend/financeteams/admin'} target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href={props.ip_address+'/backend/financeteams/admin'} target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href={props.ip_address+'/backend/financeteams/admin'} target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href={props.ip_address+'/backend/financeteams/admin'} target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: to, text
function ElementFooterLink(props)
{
    return (
        <Link to={props.to} className="footer-link">{props.text}</Link>
    );
}

// Props: text
function ElementTitleH1(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-lg-none">
                <h1 className="content-title-h1-mobile">{props.text}</h1>
            </div>
            <div className="d-none d-lg-block">
                <h1 className="content-title-h1-desktop">{props.text}</h1>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementTitleH2(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-lg-none">
                <h2 className="content-title-h2-mobile">{props.text}</h2>
            </div>
            <div className="d-none d-lg-block">
                <h2 className="content-title-h2-desktop">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementTitleH2Small(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="content-title-h2-small-mobile">{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="content-title-h2-small-desktop">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

// Props: button, education, hoursperweek, location, schedule, text, title, url
function ElementVacancyPreview(props)
{
    return (
        <div className="col-12">
            <Link to={props.url}>
                <div className="content-vacancies-container">
                    <div className="content-vacancies-top">
                        <div className="content-vacancies-title">{props.title}</div>
                        <div className="content-vacancies-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text)}}></div>
                    </div>
                    <div className="content-vacancies-bottom">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="content-vacancies-icons-container">
                                    <div className="content-vacancies-icon-marker"></div>
                                    <div className="content-vacancies-icons-title">{props.location}</div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="content-vacancies-icons-container">
                                    <div className="content-vacancies-icon-education"></div>
                                    <div className="content-vacancies-icons-title">{props.education}</div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="content-vacancies-icons-container">
                                    <div className="content-vacancies-icon-clock"></div>
                                    <div className="content-vacancies-icons-title">
                                    {props.hoursperweek}</div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="content-vacancies-icons-container">
                                    <div className="content-vacancies-icon-schedule"></div>
                                    <div className="content-vacancies-icons-title">{props.schedule}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export { ElementArticlePreview, ElementArticlePreview2, ElementArticleQuotePreview, ElementDebugResponsive, ElementFooterLink, ElementTitleH1, ElementTitleH2, ElementTitleH2Small, ElementVacancyPreview }